/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetDto } from '../models/AssetDto';
import type { ProductCodeDto } from '../models/ProductCodeDto';
import type { ProductDto } from '../models/ProductDto';
import type { ProductStatsDto } from '../models/ProductStatsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductService {

    /**
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static getApiProduct(): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static postApiProduct(
requestBody?: ProductDto,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static getApiProduct1(
id: string,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static putApiProduct(
id: string,
requestBody?: ProductDto,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/product/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ProductDto OK
     * @throws ApiError
     */
    public static putApiProductChangestatus(
id: string,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/product/{id}/changestatus',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static postApiProductImage(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/product/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static putApiProductImage(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/product/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static getApiProductCodes(
id: string,
): CancelablePromise<Array<ProductCodeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product/{id}/codes',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static postApiProductCodes(
id: string,
requestBody?: Array<ProductCodeDto>,
): CancelablePromise<Array<ProductCodeDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/product/{id}/codes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ProductStatsDto OK
     * @throws ApiError
     */
    public static getApiProductStats(
id: string,
): CancelablePromise<ProductStatsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product/{id}/stats',
            path: {
                'id': id,
            },
        });
    }

}
