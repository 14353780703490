import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

// MERCHANT
const MerchantListPage = lazy(() => import('src/pages/merchant/list'));
const MerchantDetailsPage = lazy(() => import('src/pages/merchant/details'));
const MerchantCreatePage = lazy(() => import('src/pages/merchant/new'));
const MerchantEditPage = lazy(() => import('src/pages/merchant/edit'));

// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/product/details'));
const ProductListPage = lazy(() => import('src/pages/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/product/new'));
const ProductEditPage = lazy(() => import('src/pages/product/edit'));

// PRODUCTCODE
const ProductCodeDetailsPage = lazy(() => import('src/pages/productcode/details'));
const ProductCodeListPage = lazy(() => import('src/pages/productcode/list'));

// REDEEM
const RedeemPage = lazy(() => import('src/pages/redeem/list'));
const RedeemDetailsPage = lazy(() => import('src/pages/redeem/details'));

// MERCHANTREPORT
const MerchantReportListPage = lazy(() => import('src/pages/merchantreport/list'));
const MerchantReportDetailsPage = lazy(() => import('src/pages/merchantreport/details'));

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [       
      {
        path: 'merchant',
        children: [
          { element: <MerchantListPage />, index: true },
          { path: 'list', element: <MerchantListPage /> },
          { path: 'details/:id', element: <MerchantDetailsPage /> },
          { path: ':id/edit', element: <MerchantEditPage /> },
          { path: 'new', element: <MerchantCreatePage /> }, 
          { path: 'new/:id', element: <MerchantCreatePage /> },         
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'details/:id', element: <ProductDetailsPage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: 'new/:id', element: <ProductCreatePage /> },        
        ],
      },
      {
        path: 'productcode',
        children: [
          { element: <ProductCodeListPage />, index: true },
          { path: 'list', element: <ProductCodeListPage /> },
          { path: 'details/:id', element: <ProductCodeDetailsPage /> },     
        ],
      },
      {
        path: 'merchantreport',
        children: [
          { element: <MerchantReportListPage />, index: true },
          { path: 'list', element: <MerchantReportListPage /> },
          { path: ':id', element: <MerchantReportDetailsPage /> },
        ],
      },
      {
        path: 'redeem',
        children: [
          { element: <RedeemPage />, index: true },
          { path: 'list', element: <RedeemPage /> },
          { path: 'details/:id', element: <RedeemDetailsPage /> },
        ],
      },
      { path: '404', element: <Page404 /> },
    ],
  },
];
